import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { ServiceToDto } from '../models/serviceToDto';
import { ServiceToNameDto } from '../models/serviceToNameDto';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '@env/environment';
import { UntypedFormGroup } from '@angular/forms';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { FileInfo } from '@progress/kendo-angular-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { LookUpDisplayItem } from '@app/core/models/look-up-display-item';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { PayPlanDto } from '../models/payPlanDto';
import { Guid } from '@app/shared/models/guid';
import { ISelectItem } from '@app/shared/models/select-item';
import { LookUpServiceToFilterDto } from '../models/Search/LookUpServiceToFilterDto';
import { CyrismaInstanceSettingsDto } from '../models/cyrismaInstanceSettingsDto';
import { ServiceToLiteDto } from '../models/ServiceToLiteDto';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
/**
 * Interface for IServiceToService
 */
export interface IServiceToService {
	url: string;
	get(id: string): Observable<ServiceToDto>;
	getByUniversalId(universalId: string): Observable<ServiceToDto>;
	save(formGroup: UntypedFormGroup): Observable<{ id: string }>;
	saveFile(files: FileInfo[], entityId: string): Observable<any>;
	removeFile(entityId: string): Observable<any>;
	getByClientId(clientId: string): Observable<LookUpDisplayItem<string>[]>;
	getAllByTenancy(): Observable<LookUpDisplayItem<string>[]>;
	getAllLookUpByTenancyForUser(): Observable<LookUpDisplayItem<string>[]>;
	getMyServiceToName(): Observable<ServiceToNameDto>;
	getLookUpFiltered(lookUpServiceToFilterDto: LookUpServiceToFilterDto, useUniversalId: boolean);
	getHRServicetos(): Promise<LookUpDisplayItem<string>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ServiceToService extends BaseApiClient {
	public url: string; // URL to serviceTo web api
	public baseUrl: string; // base Url to web api
	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment,
		private domSanitizer: DomSanitizer
	) {
		super();
		this.baseUrl = environment.services['clients'];
		this.url = this.baseUrl + 'ServiceTo';
	}
	/**
	 * Retrieves the entity
	 * @param id
	 */
	get(id: string): Observable<ServiceToDto> {
		const url = `${this.url}/${id}`;
		this.log.logVerbose('ServiceTo Get with id:' + id);
		return this.http.get<ServiceToDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo id=${id}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}
	/**
	 * Retrieves the entity
	 * @param universalId
	 */
	getByUniversalId(universalId: string): Observable<ServiceToDto> {
		const url = `${this.url}/GetByUniversalId/${universalId}`;
		this.log.logVerbose('ServiceTo GetByUniversalId with universalId:' + universalId);
		return this.http.get<ServiceToDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo universalId=${universalId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieves the entity
	 * @param universalId
	 */
	getCyrismaSettings(universalId: string): Observable<CyrismaInstanceSettingsDto> {
		const url = `${this.url}/crysma/${universalId}`;
		this.log.logVerbose('getCyrismaSettings with universalId:' + universalId);
		return this.http.get<CyrismaInstanceSettingsDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo universalId=${universalId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieves the entity
	 * @param universalId
	 */
	createCyrismaSettings(universalId: string, email: string): Observable<CyrismaInstanceSettingsDto> {
		const url = `${this.url}/crysma/${universalId}?email=${email}`;
		this.log.logVerbose('createCyrismaSettings with universalId:' + universalId);
		return this.http.post<CyrismaInstanceSettingsDto>(url, null).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo universalId=${universalId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	async getHRServicetos(): Promise<LookUpDisplayItem<string>[]> {
		const url = `${this.url}/GetAll`;
		this.loadingIndicatorService.startLoading();
		return await this.http
			.get<ServiceToDto[]>(url)
			.pipe(
				tap((): void => {
					this.log.logInformation(`ServiceTo GetAll`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading())),
				map((serviceTos) =>
					serviceTos
						.filter((serviceTo) => serviceTo.isActive && serviceTo.locationAppFeatures.some((x) => x.featureName === 'HrDrive'))
						.map<LookUpDisplayItem<string>>((serviceTo) => {
							return {
								id: serviceTo.id,
								display: serviceTo.name,
								lookUp: serviceTo.name,
								selectable: true,
								sort: 0,
								rank: 0,
							};
						})
				)
			)
			.toPromise();
	}

	async getAll(): Promise<ISelectItem<string>[]> {
		const url = `${this.url}/GetAll`;
		this.loadingIndicatorService.startLoading();
		return await this.http
			.get<ServiceToDto[]>(url)
			.pipe(
				tap((): void => {
					this.log.logInformation(`ServiceTo GetAll`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading())),
				map((serviceTos) =>
					serviceTos
						.filter(
							(serviceTo) =>
								serviceTo.isActive &&
								serviceTo.locationAppFeatures.some((x) => x.featureName === 'EHS') &&
								!(
									serviceTo.option.mkoAccountType === 3 ||
									serviceTo.option.mkoAccountType === 11 ||
									serviceTo.option.mkoAccountType === 12 ||
									serviceTo.option.mkoAccountType === 13 ||
									serviceTo.option.mkoAccountType === 15 ||
									serviceTo.option.mkoAccountType === 21 ||
									serviceTo.option.mkoAccountType === 22
								)
						)
						.map<ISelectItem<string>>((serviceTo) => {
							return {
								label: serviceTo.name,
								value: serviceTo.id,
							};
						})
				)
			)
			.toPromise();
	}

	async getServiceToPromise(): Promise<ServiceToDto[]> {
		this.loadingIndicatorService.startLoading();
		return await this.getServiceTo()
			.pipe(
				tap((): void => {
					this.log.logInformation(`ServiceTo GetAll`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
			)
			.toPromise();
	}

	getServiceTo(clientId: string = null): Observable<ServiceToDto[]> {
		let url = `${this.url}/GetAll`;

		if (clientId) {
			url = `${url}?clientId=${clientId}`;
		}
		return this.http.get<ServiceToDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`ServiceTo GetAll`);
			}),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getServiceToName(servicetoId: string): Observable<ServiceToNameDto> {
		const url = `${this.url}/${servicetoId}/Name`;
		this.log.logVerbose('ServiceTo Get Name:');
		return this.http.get<ServiceToNameDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getAllByTenancy(): Observable<LookUpDisplayItem<string>[]> {
		const url = `${this.url}/GetAllLookup`;
		this.log.logVerbose('ServiceTo GetAllLookup');
		return this.http.get<LookUpDisplayItem<string>[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo Lookup`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getAllLookUpByTenancyForUser(ssoUserId: string, useUniversalId: boolean): Observable<LookUpDisplayItem<string>[]> {
		const url = `${this.url}/GetAllLookup?ssoUserId=${ssoUserId}&useUniversalId=${useUniversalId}`;
		this.log.logVerbose(`ServiceTo getAllLookUpByTenancyForUser for ssoUserId: ${ssoUserId} and useUniversalId: ${useUniversalId}`);
		return this.http.get<LookUpDisplayItem<string>[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo getAllLookUpByTenancyForUser`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getLookUpFiltered(lookUpServiceToFilterDto: LookUpServiceToFilterDto, useUniversalId: boolean): Observable<LookUpDisplayItem<string>[]> {
		const url = `${this.url}/LookUpFiltered?useUniversalId=${useUniversalId}`;
		this.log.logVerbose(`ServiceTo getLookUpFiltered: useUniversalId: ${useUniversalId}`);
		return this.http.post<LookUpDisplayItem<string>[]>(url, lookUpServiceToFilterDto).pipe(
			tap((): void => this.log.logVerbose(`fetched ServiceTo getLookUpFiltered`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getByClientId(clientId: string): Observable<LookUpDisplayItem<string>[]> {
		const url = `${this.url}/GetByClientId/${clientId}`;
		this.log.logVerbose('ServiceTo GetByClientId with clientId:' + clientId);
		return this.http.get<LookUpDisplayItem<string>[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo clientId=${clientId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}
	/**
	 * Updates or creates an entity
	 * @param entity
	 */
	save(formGroup: UntypedFormGroup): Observable<any> {
		const entity = formGroup.getRawValue(); // Get the rawValue as it includes values from disabled fields
		if (entity.option.visitValue == null && entity.option.sfVisitValue == null) {
			entity.option = null;
		}

		this.loadingIndicatorService.startLoading();
		const errorHandler = this.createErrorResponseHandler(this.log, formGroup, (_) => this.loadingIndicatorService.stopLoading());
		if (entity.id == null) {
			return this.http.post(this.url, entity, httpOptions).pipe(
				tap((id) => {
					this.log.logInformation(`created entity id=${id}`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(errorHandler)
			);
		} else {
			return this.http.put<any>(this.url + '/' + entity.id, entity, httpOptions).pipe(
				tap((id) => {
					this.log.logInformation(`update entity id=${id}`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(errorHandler)
			);
		}
	}

	saveFile(files: FileInfo[], entityId: string): Observable<any> {
		//const entity = formGroup.value;
		// var finalEntityId = (entity.id === null && entityId && entityId.trim() !== "") ? entityId : entity.id;

		const url = `${this.url}/${entityId}/Logo`;
		const errorHandler = this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading());

		var formData = new FormData();
		formData.append('file', files[0].rawFile);

		var headers = new HttpHeaders({ enctype: 'multipart/form-data' });

		this.loadingIndicatorService.startLoading();
		return this.http.post(url, formData, { headers: headers }).pipe(
			tap((): void => this.loadingIndicatorService.stopLoading()),
			catchError(errorHandler)
		);
	}

	removeFile(entityId: string): Observable<any> {
		const url = `${this.url}/${entityId}/Logo`;
		const errorHandler = this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading());

		return this.http.delete(url, httpOptions).pipe(
			tap((): void => this.loadingIndicatorService.stopLoading()),
			catchError(errorHandler)
		);
	}

	getLogo(entityId: string): Observable<any> {
		const url = `${this.url}/${entityId}/Logo`;
		const errorHandler = this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading());
		this.loadingIndicatorService.startLoading();
		return this.http.get(url, { responseType: 'blob' }).pipe(
			map((e) => this.domSanitizer.bypassSecurityTrustStyle(`url(${URL.createObjectURL(e)})`)),
			tap((): void => this.loadingIndicatorService.stopLoading()),
			catchError(errorHandler)
		);
	}

	/**
	 * Get pay plans on universal id
	 * @param universalId
	 */
	getPayPlans(universalId: string): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);

		if (universalId) {
			const url = `${this.url}/${universalId}/PayPlans`;
			return this.http.get<PayPlanDto>(url).pipe(
				tap((): void => this.log.logInformation(`fetched pay plans universalId=${universalId}`)),
				catchError(errorHandler)
			);
		}
	}

	/**
	 * Retrieves all lite serviceTos for a client
	 * @param universalId
	 */
	getServiceToLiteForClient(clientUniversalId: string): Observable<ServiceToLiteDto[]> {
		const url = `${this.url}/GetServiceToLiteForClient/${clientUniversalId}`;
		return this.http.get<ServiceToLiteDto[]>(url).pipe(
			tap((): void => this.log.logInformation(`fetched ServiceTo universalId=${clientUniversalId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieves all lite serviceTos for a client
	 * @param universalId
	 */
	getServiceToLiteForClientAndApplicationFeature(clientUniversalId: string, applicationFeatureId: number): Observable<ServiceToLiteDto[]> {
		const url = `${this.url}/GetServiceToLiteForClientAndAppFeatureId/${clientUniversalId}/${applicationFeatureId}`;
		return this.http.get<ServiceToLiteDto[]>(url).pipe(
			tap((): void =>
				this.log.logInformation(`fetched ServiceTo universalId=${clientUniversalId} and applicationFeatureId=${applicationFeatureId}`)
			),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}
}
