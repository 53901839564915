import { IEnvironment } from './ienvironment';

export class Environment implements IEnvironment {
	static production: boolean = true;

	private static readonly logLevelSetting: string = '#{ui.logLevel}';
	private static readonly logToConsoleSetting: string = '#{ui.logToConsole}';

	constructor() {
		this.logLevel = parseInt(Environment.logLevelSetting);
		this.logToConsole = Environment.logToConsoleSetting;
	}

	production: boolean = Environment.production;
	logToConsole: string = 'true';
	logLevel: number = 2;

	appInsightsConnectionString: string = '#{ApplicationInsights:ConnectionString}';

	services: { [serviceName: string]: string } = {
		site: '#{Services:Site}/',
		sso: '#{Services:Sso:Authority}/',
		employees: '#{Services:PeopleApi}/',
		clients: '#{Services:ClientsApi}/',
		audit: '#{Services:AuditApi}/',
		security: '#{Services:SecurityApi}/',
		northStarAlpha: '',
		products: '#{Services:ProductsApi}/',
		navigation: '#{Services:NavigationApi}/',
		dashboard: '#{Services:DashboardApi}/',
		documents: '#{Services:DocumentsApi}/',
		batchimport: '#{Services:BatchImportApi}/',
		reporting: '#{Services:ReportingApi}/',
		incidents: '#{Services:IncidentsApi}/',
		features: '#{Services:FeaturesApi}/',
		notifications: '#{Services:NotificationApi}/',
		events: '#{Services:EventsApi}/',
		veratrack: '#{Services:VeraTrackApi}/',
		equipment: '#{Services:EquipmentApi}/',
		fileManagement: '#{Services:FileManagementApi}/',
		ableAPI: '#{AbleAPI:BaseURL}',
		glba: '#{Services:GLBAApi}/',
		passthrough: '#{Services:PassthroughApi}/',
	};

	preAuthLanding: { platform: string; hrDrive: string; myKpaOnline: string } = {
		platform: '#{baseUrl.platform}',
		hrDrive: '#{baseUrl.hrDrive}',
		myKpaOnline: '#{baseUrl.myKpaOnline}',
	};

	/**
	 * Base domain address to applications
	 */
	baseDomain: { platform: string; hrDrive: string; myKpaOnline: string } = {
		platform: '#{baseUrl.platform}',
		hrDrive: '#{baseUrl.hrDrive}',
		myKpaOnline: '#{baseUrl.myKpaOnline}',
	};

	signalr: { onboarding: string } = {
		onboarding: '#{signalr.onboarding}',
	};

	salesForceUrl: string = '#{ui.salesForceUrl}';

	appStoreUrls: { android: string; iOS: string } = {
		android: 'https://play.google.com/store/apps/details?id=com.mykpaonline.mykpaonsite&hl=en_US&gl=US',
		iOS: 'https://apps.apple.com/us/app/kpa/id1421958302',
	};

	thunderSf = {
		embeddedUri: '#{thunderSf.embeddedUri}',
		id: '#{thunderSf.id}',
		scrt2Uri: '#{thunderSf.scrt2Uri}',
		source: '#{thunderSf.source}',
	};

	recaptcha = {
		siteKey: '6LfxfbYUAAAAAP8fUPy7POaTkWCAHEqonVg6iRCN',
	};
}
